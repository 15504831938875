import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsNlNl from "@landingpage/app/src/locales/nl_NL.json";
import translationsNB from "@landingpage/app/src/locales/nb_NO.json";
import translationsSV from "@landingpage/app/src/locales/sv_SE.json";
import translationsENGB from "@landingpage/app/src/locales/en_GB.json";
import translationsENAU from "@landingpage/app/src/locales/en_AU.json";
import translationsENIE from "@landingpage/app/src/locales/en_IE.json";
import translationsENNZ from "@landingpage/app/src/locales/en_NZ.json";

const resources = {
  nl: { translation: translationsNlNl },
  be: { translation: translationsNlNl },
  no: { translation: translationsNB },
  se: { translation: translationsSV },
  gb: { translation: translationsENGB },
  au: { translation: translationsENAU },
  ie: { translation: translationsENIE },
  nz: { translation: translationsENNZ },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "gb",
  interpolation: {
    escapeValue: false,
  },
  wait: true,
});

export default i18n;
