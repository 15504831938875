import React, { createContext, useContext, useEffect, useState } from "react";
import { add, parseISO, isAfter } from "date-fns";
import axios from "axios";

const RegistrationContext = createContext();
export const useRegistration = () => useContext(RegistrationContext);

const apiUser = axios.create({
  baseURL: `https://dc-${process.env.GATSBY_APP_CHANNEL_IDENTIFIER}-ms-${
    process.env.GATSBY_APP_ACCEPTANCE === "true" ? "user-acceptance" : "user"
  }.axprod.net`,
});

const apiSubscription = axios.create({
  baseURL: `https://dc-${process.env.GATSBY_APP_CHANNEL_IDENTIFIER}-ms-${
    process.env.GATSBY_APP_ACCEPTANCE === "true"
      ? "subscription-acceptance"
      : "subscription"
  }.axprod.net`,
});

const RegistrationProvider = ({ children }) => {
  const [apiErrors, setApiErrors] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [token, setToken] = useState(null);
  const [isAuth, setIsAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentPlan, setPaymentPlan] = useState(null);
  const [language, setLanguage] = useState(null);
  const [country, setCountry] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [plans, setPlans] = useState(null);

  const checkUserTokenIsValid = async (axinomToken) => {
    const user = await apiUser.get("/v1/user", {
      headers: {
        authorization: `bearer ${axinomToken}`,
      },
    });

    if (user.status === 200) {
      setUser(user.data);
    }

    return user.status;
  };

  const hasSubscription = async (axinomToken, countryCode) => {
    const subscriptionsData = await apiSubscription.get(
      `/v1/subscription?translation=${countryCode}`,
      {
        headers: {
          authorization: `bearer ${axinomToken}`,
        },
      }
    );

    if (subscriptionsData.data.length > 0) {
      const activeArray = subscriptionsData.data.filter((sub) => {
        if (sub.state === "paused") {
          return true;
        }
        if (!sub.subscription_end) {
          return false;
        }

        return isAfter(
          add(parseISO(sub.subscription_end), { days: 1 }),
          new Date()
        );
      });

      if (activeArray.length > 0) setActiveSubscription(true);
    }

    setActiveSubscription(false);
  };

  const loadPlans = async (storageToken, languageCode, countryCode) => {
    const lang = languageCode.split("-")[0].toLocaleLowerCase();

    const plansData = await apiSubscription.get(
      `/v1/subscriptionplan?translation=${lang}&country=${countryCode}`,
      {
        headers: {
          authorization: `bearer ${storageToken}`,
        },
      }
    );

    setPlans(plansData.data);
  };

  useEffect(() => {
    if (user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user_id: user?.id,
        event: "user_loaded",
      });
    }
  }, [user]);

  // Store all UTM tags is a Session for onboarding
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const utmTags = Object.keys(params)
      .filter((key) => key.includes("utm"))
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
      }, {});

    if (!sessionStorage.getItem("utmTags") && Object.keys(params).length > 0) {
      sessionStorage.setItem("utmTags", JSON.stringify(utmTags));
    }
  }, []);

  useEffect(() => {
    const urlMatch = window.location.href.match(/\/[a-z]{2}-[a-z]{2}/g);
    if (urlMatch) {
      const languageValue = urlMatch[0]
        .toUpperCase()
        .replace("/", "")
        .toUpperCase()
        .replace("/", "");

      const countryCode = urlMatch[0]
        .split("-")[1]
        .toLocaleLowerCase()
        .replace("/", "");

      setLanguage(languageValue);
      setCountry(countryCode);

      const storageToken = window.localStorage.getItem("token");
      if (storageToken) {
        setLoadingScreen(true);
        checkUserTokenIsValid(storageToken)
          .then((userReponse) => {
            if (userReponse === 200) {
              setToken(storageToken);
              hasSubscription(storageToken, countryCode);
              loadPlans(storageToken, languageValue, countryCode);
              setLoadingScreen(false);
              setIsAuth(true);
            } else {
              setIsAuth(false);
            }
          })
          .catch(() => setIsAuth(false));
      }

      setIsAuth(false);
    }
  }, []);

  return (
    <RegistrationContext.Provider
      value={{
        apiErrors,
        setApiErrors,
        loadingButton,
        setLoadingButton,
        loadingScreen,
        setLoadingScreen,
        token,
        selectedPlan,
        setSelectedPlan,
        language,
        country,
        apiUser,
        apiSubscription,
        setToken,
        isAuth,
        setIsAuth,
        paymentPlan,
        setPaymentPlan,
        activeSubscription,
        plans,
        user,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationProvider;
