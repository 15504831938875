import * as React from "react";
import * as Sentry from "@sentry/browser";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import RegistrationProvider from "./src/components/Registration/RegistrationProvider";
import LocaleProvider from "./src/i18n/LocaleProvider";

// eslint-disable-next-line no-unused-vars
import i18n from "./src/i18n/i18n";

Sentry.setContext("channel", {
  name: process.env.GATSBY_APP_CHANNEL_IDENTIFIER,
});

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
);

export const wrapPageElement = ({ element, props }) => {
  return (
    <LocaleProvider pageContext={props.pageContext}>
      <RegistrationProvider value={props.pageContext}>
        {element}
      </RegistrationProvider>
    </LocaleProvider>
  );
};
