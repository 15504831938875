import React, { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LocaleContext = createContext();
export const useLocale = () => useContext(LocaleContext);

const LocaleProvider = ({ pageContext, children }) => {
  const { i18n } = useTranslation();
  const { lang } = pageContext;

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang.split("-")[1].toLocaleLowerCase());
    }
  }, [lang])

  return <LocaleContext.Provider>{children}</LocaleContext.Provider>;
};

export default LocaleProvider;
